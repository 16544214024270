import emailAddresses from '@root/src/emailAddresses';

export default [
  {
    title: 'Start',
    fields: [
      {
        title: 'Kund/varumärke',
        id: 'client',
        description: 'Vem är kunden? Vilket varumärke gäller det?',
        type: 'text',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Projektnamn',
        id: 'title',
        description: 'Vad kallar vi projektet?',
        type: 'text',
        placeholder: 'Skriv här...',
      },
    ],
  },
  {
    title: 'Allmänt',
    fields: [
      {
        title: 'Deadline',
        description: 'Vilket eller vilka datum behöver vi förhålla oss till?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Projektansvarig',
        description: 'Vem äger projektet? Projektledare, kundansvarig eller liknande.',
        type: 'text',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Kontakt hos kund',
        description: 'Vem är vår huvudsakliga kontaktperson hos kunden?',
        type: 'text',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Budget',
        description: 'Finns det någon budget satt? Om ja, vad är den på? (Även spann eller ungefärliga summor går bra)',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
    ],
  },
  {
    title: 'Uppdrag',
    fields: [
      {
        title: 'Projekt/uppdrag',
        description: 'Vad är det som ska göras?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Syfte och mål',
        description: 'Varför ska vi göra det här? Vad är problemet som ska lösas eller möjligheten vi ska utnyttja? Vad vill vi uppnå?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Mätbara mål',
        description: 'Om inget är satt, ge förslag eller exempel på vad som skulle kunna vara mätbara mål.',
        type: 'text',
        placeholder: 'Skriv här...',
      },
    ],
  },
  {
    title: 'Viktigt att veta',
    fields: [
      {
        title: 'Personer',
        description: 'Vilka nyckelpersoner eller särskilda kompetenser behöver vara med?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Målgrupp',
        description: 'Vem riktar detta sig till?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Nödvändigt material',
        description: 'Var finns det vi behöver eller vem har det? Till exempel typsnitt, logo, lösenord etc.',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
    ],
  },
  {
    title: 'Bra att veta',
    fields: [
      {
        title: 'Underlag/identitet',
        description: 'Vad har vi att förhålla oss till? Grafisk profil, tonalitet, budskap etc.',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Inspiration',
        description: 'Vad finns det för referenser, moodboard eller annan relevant inspiration?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
      {
        title: 'Övrigt',
        description: 'Finns det något mer att berätta?',
        type: 'textarea',
        placeholder: 'Skriv här...',
      },
    ],
  },
  {
    title: 'Slutför',
    description: 'Skicka briefen med e-post.',
    fields: [
      {
        title: 'Avsändare',
        id: 'senders',
        description: 'Vem/vilka är projektledare?',
        type: 'autocomplete-choice',
        options: emailAddresses.emailRecipients,
        placeholder: 'Välj avsändare...',
      },
      {
        title: 'Mottagare',
        id: 'recipients',
        description: 'Vem/vilka ska få briefen?',
        type: 'autocomplete-choice',
        options: emailAddresses.emailRecipients,
        placeholder: 'Välj mottagare...',
      },
    ],
    formEnding: true,
  },
];
