import React from 'react';
import { AppContextProvider } from 'Components/AppContext';
import { Theme, GlobalStyles } from 'Components/AppTheme';
import BeyondBrief from '@root/src/BeyondBrief';
import './fonts/PlainUltralight-Regular.css';
import './fonts/PlainBold-Regular.css';
import './fonts/PlainBlack-Regular.css';

function App() {
  return (
    <>
      <AppContextProvider>
        <GlobalStyles />
        <Theme>
          <div className="App">
            <BeyondBrief />
          </div>
        </Theme>
      </AppContextProvider>
    </>
  );
}

export default App;
