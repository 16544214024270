import React, {
  useEffect,
  useState,
} from 'react';

export default ({
  keys = [],
  children,
  callback,
}) => {
  const [sequenceCorrect, setSequenceCorrect] = useState(false);

  useEffect(() => {
    const typingLimit = 1000;
    const comboKeys = [
      ['shift', [16], 'shiftKey'],
      ['ctrl', [17], 'metaKey'],
      ['alt', [18], 'altKey'],
      ['cmd', [91, 93], 'metaKey'],
    ];
    let timeoutID;
    let currentKeyIndex = 0;

    const checkKey = (keyCode, event) => {
      const keysSplitted = (keys[currentKeyIndex] + '').split('+');
      let specialKeysOK = true;

      comboKeys.forEach((comboKey) => {
        const name = comboKey[0];
        // const keyCodes = comboKey[1];
        const eventName = comboKey[2];

        if (keysSplitted.indexOf(name) > -1 && !event[eventName]) {
          specialKeysOK = false;
        }
      });

      if (keysSplitted[keysSplitted.length - 1] === keyCode && specialKeysOK) {
        currentKeyIndex += 1;

        if (currentKeyIndex === keys.length) {
          currentKeyIndex = 0;
          setSequenceCorrect(true);
          callback && callback(keys);

        } else {
          timeoutID = setTimeout(() => {
            currentKeyIndex = 0;
          }, typingLimit);
        }

      } else {
        currentKeyIndex = 0;
      }
    };

    const onKeyDown = (event) => {
      if (
        document.activeElement.tagName !== 'INPUT' &&
        document.activeElement.tagName !== 'TEXTAREA' &&
        document.activeElement.tagName !== 'SELECT'
      ) {
        let notComboKey = true;

        comboKeys.forEach((comboKey) => {
          const keyCodes = comboKey[1];

          if (keyCodes.indexOf(event.keyCode) > -1) {
            notComboKey = false;
          }
        });

        if (notComboKey) {
          clearTimeout(timeoutID);
          const keyChar = (event.keyCode >= 65 && event.keyCode <= 90) ? String.fromCharCode(event.keyCode).toLowerCase() : event.keyCode.toString(10);

          if (keyChar) {
            checkKey(keyChar, event);
          }
        }
      }
    };

    if (document) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (document) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [callback, keys]);

  return sequenceCorrect ? (
    <>
      { children }
    </>
  ) : null;
};
