import React from 'react';
import styled from '@emotion/styled/macro';
import Icon from 'Elements/Icon';

export const IconButton = styled('div')`
  display: flex;
  align-items: center;
  transition: color ${({ theme }) => theme.misc.transition};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  .icon {
    margin-right: 6px;
    font-size: .75rem;
    display: block;
    position: relative;
  }

  span {
    font-weight: 400;
    font-family: 'PlainBold', sans-serif;
    font-size: 16px;
  }

  ${({ icon }) => icon === 'times' && `
    .icon {
      font-size: .5rem;
      top: 1px;
    }
  `}
`;

export default ({ icon, text, onClick, iconSize }) => {
  return (
    <IconButton onClick={onClick} icon={icon} iconSize={iconSize}>
      <Icon name={icon} />
      <span>{text}</span>
    </IconButton>
  );
};
