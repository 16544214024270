import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';

export const PreviewBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  background: rgba(0, 0, 0, .9);
  display: none;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.misc.transition};

  ${({ open }) => open && `display: block;`}
  ${({ visible }) => visible && `opacity: 1;`}

  iframe {
    width: 100%;
    height: calc(100% - ${({ theme }) => theme.sizes.headerHeight} - 40px);
    position: relative;
    top: calc(${({ theme }) => theme.sizes.headerHeight} + 20px);
    max-width: 700px;
    margin: 0 auto;
    display: block;
    border: none;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .3);
  }
`;

export const PreviewFrame = () => {
  const { appState } = useContext(AppContext);
  const { briefPDF } = appState;

  const objectURL = briefPDF.getObjectURL();

  return (
    <iframe title="Förhandsvisning av brief" src={objectURL} />
  );
};

export default ({
  open,
  setOpen,
}) => {
  const [visible, setVisibililty] = useState(false);

  useEffect(() => {
    setVisibililty(open);
  }, [open]);

  const onClick = (event) => {
    if (event.target.classList.contains('preview-background')) {
      setOpen(false);
    }
  };

  return (
    <PreviewBG className="preview-background" open={open} visible={visible} onClick={onClick}>
      {open && <PreviewFrame />}
    </PreviewBG>
  );
};
