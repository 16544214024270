import React, { useContext, useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';
import AutocompleteChoice from 'Blocks/AutocompleteChoice';
import { CheckboxGroup } from 'Blocks/Checkbox';

export const Field = styled.div`

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.space[5]};
  }

  .field-description,
  label {
    display: block;
    margin-bottom: 0;
  }

  .field-description {
    font-weight: 200;
    margin-bottom: ${({ theme }) => theme.space[1]};
    line-height: 1.35rem;
  }

  label {
    font-weight: 400;
    font-family: 'PlainBold', sans-serif;
    margin-bottom: 2px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  textarea,
  input[type="date"] {
    appearance: none;
    width: 100%;
    border: none;
    font-size: 20px;
    font-weight: 400;
    font-family: 'PlainUltralight', sans-serif;
    background: ${({ theme }) => theme.colors.greyLight};
    border-radius: 3px;
    padding: ${({ theme }) => theme.space[2]};
    outline: none;
  }

  textarea {
    min-width: 100%;
    max-width: 100%;
    height: 100px;
  }

  fieldset {
    margin: 16px 0px 0px;
    padding: 0;
    border: none;

    label {
      font-weight: 200;
    }
  }
`;

const ValueElem = ({
  type,
  placeholder,
  id,
  onChange,
  options,
  value,
}) => {

  if (type === 'textarea') {
    return <textarea id={id} placeholder={placeholder} onChange={onChange} value={value} />;

  } if (type === 'checkbox') {
    return <CheckboxGroup id={id} options={options} onChange={onChange} value={value} />

  } else if (type === 'autocomplete-choice') {
    return <AutocompleteChoice placeholder={placeholder} options={options} onChange={onChange} value={value} />;

  } else {
    return <input id={id} type={type} value={value} placeholder={placeholder} onChange={onChange}/>;
  }
};

export default ({ data, index, slideIndex }) => {
  const { appState } = useContext(AppContext);
  const { fields, setFields } = appState;
  const {
    title,
    type = 'text',
    description,
    placeholder,
    options,
    id,
  } = data;

  const onChange = useCallback(
    (e) => {
      const value = e.target ? e.target.value : e;

      const fieldObj = {
        title,
        value: value,
      };

      if (id) {
        fieldObj.id = id;
      }

      const newFields = Object.assign({}, fields, { [`${slideIndex}-${index}`]: fieldObj });
      setFields(newFields);
      localStorage.setItem('fields', JSON.stringify(newFields));
    },
    [title, id, fields, setFields, slideIndex, index],
  );

  const value = fields[`${slideIndex}-${index}`] ? fields[`${slideIndex}-${index}`].value : null;

  const valueElemProps = {
    type,
    placeholder,
    onChange,
    options,
    value: value || '',
    id: `field-${slideIndex}-${index}`,
  };

  return (
    <Field>
      <label htmlFor={`field-${slideIndex}-${index}`}>{title}</label>
      {description && (<span className="field-description">{description}</span>)}
      <ValueElem {...valueElemProps} />
    </Field>
  );
};
