import React, { useState, createRef, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import Icon from 'Elements/Icon';

export const AutocompleteChoice = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.greyLight};
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 10px;
  padding-top: 10px;

  input[type="text"] {
    appearance: none;
    width: 100%;
    border: none;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Plain', sans-serif;
    outline: none;
    background: ${({ theme }) => theme.colors.greyLight};
    flex: 1 1 175px;
    padding: ${({ theme }) => theme.space[2]};
    padding-top: calc(${({ theme }) => theme.space[2]} - 10px);
    padding-left: calc(${({ theme }) => theme.space[2]} - 10px);
  }
`;

export const Recipient = styled.div`
  margin: 0px 10px 10px 0px;
  padding: 6px ${({ theme }) => theme.space[2]};
  padding-right: 12px;
  border-radius: 3px;
  background: #fff;
  display: flex;
  align-items: center;

  .icon-holder {
    display: block;
    margin-left: 8px;
    cursor: pointer;
    transition: color ${({ theme }) => theme.misc.transition};
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    .icon {
      display: block;
      width: 10px;
      height: 10px;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`;

export const SearchSuggestionsHolder = styled.div`
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 300;
  width: 100%;
  font-size: 16px;

  ${({ theme, suggestions }) => suggestions.length > 0 && `
    border: 1px solid ${theme.colors.greyLight};
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, .1);
  `}
`;

export const SearchSuggestionItem = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  cursor: pointer;
  transition: color ${({ theme }) => theme.misc.transition};

  strong {
    font-weight: 400;
    font-family: 'PlainBold', sans-serif;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  ${({ active, theme }) => active && `
    color: ${theme.colors.brand};
  `}
`;

const SearchSuggestions = ({ suggestions, onSuggestionClick, activeItem }) => {
  return (
    <SearchSuggestionsHolder suggestions={suggestions}>
      {suggestions.map((suggestion, index) => {

        return (
          <SearchSuggestionItem active={activeItem === index + 1} onClick={() => { onSuggestionClick(suggestion) }} key={index}>
            <strong>{suggestion.key}</strong> ({suggestion.value})
          </SearchSuggestionItem>
        );
      })}
    </SearchSuggestionsHolder>
  );
};

const RecipientSelection = ({ selections, removeSelection }) => {
  return (
    <>
      {Object.keys(selections).map((selection, index) => {
        return (
          <Recipient key={index} value={selections[selection]}>
            <span>{selection}</span>
            <div className="icon-holder" onClick={() => removeSelection(selection)}>
              <Icon name="times" />
            </div>
          </Recipient>
        );
      })}
    </>
  );
};

export default ({
  onChange,
  placeholder,
  options = {},
  value = {},
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selections, setSelections] = useState(value);
  const [activeSuggestionItem, setActiveSuggestionItem] = useState(1);
  const inputRef = createRef();

  const onSearchChange = (e) => {
    const searchTerm = e.target ? e.target.value : e;
    const searchSuggestions = [];
    setSearchValue(searchTerm);

    if (searchTerm) {
      Object.keys(options).forEach((option) => {
        if (option.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
          searchSuggestions.push({
            key: option,
            value: options[option],
          });
        }
      });
    }
    setSuggestions(searchSuggestions);
  };

  const removeSelection = (selection) => {
    const newSelections = Object.assign({}, selections);
    delete newSelections[selection];
    setSelections(newSelections);
    onChange(newSelections);
  };

  const onSuggestionClick = (clicked) => {
    setSearchValue('');
    setSuggestions([]);

    const newSelections = Object.assign({}, (selections || {}), {
      [clicked.key]: clicked.value,
    });

    setSelections(newSelections);
    onChange(newSelections);

    inputRef.current.focus();
  };

  const onBlur = () => {
    setTimeout(function () {
      onSearchChange('');
    }, 200);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 40) {
      e.preventDefault();
      setActiveSuggestionItem(activeSuggestionItem + 1 >= suggestions.length ? suggestions.length : activeSuggestionItem + 1);

    } else if (e.keyCode === 38) {
      e.preventDefault();
      setActiveSuggestionItem(activeSuggestionItem - 1 <= 0 ? 0 : activeSuggestionItem - 1);

    } else if (e.keyCode === 13) {
      e.preventDefault();
      const arrowSelectedSuggestion = suggestions[activeSuggestionItem - 1];

      if (arrowSelectedSuggestion) {
        setSearchValue('');
        setSuggestions([]);

        const newSelections = Object.assign({}, (selections || {}), {
          [arrowSelectedSuggestion.key]: arrowSelectedSuggestion.value,
        });

        setActiveSuggestionItem(1);

        setSelections(newSelections);
        onChange(newSelections);
        inputRef.current.focus();
      }
    } else if (e.target.value === '' && e.keyCode === 8) {
      const selectionKeys = Object.keys(selections);
      const lastSelection = selectionKeys[selectionKeys.length - 1];
      removeSelection(lastSelection);
    }
  };

  useEffect(() => {
    setSelections(value);
  }, [value]);

  return (
    <AutocompleteChoice>
      <RecipientSelection selections={selections} removeSelection={removeSelection} />
      <input ref={inputRef} type="text" placeholder={placeholder} value={searchValue} onKeyDown={onKeyDown} onChange={onSearchChange} onBlur={onBlur} />
      <SearchSuggestions activeItem={activeSuggestionItem} suggestions={suggestions} onSuggestionClick={onSuggestionClick} />
    </AutocompleteChoice>
  );
};
