import React, { createContext, useState } from 'react';
import formData from '@root/src/formData';
import briefPDF from '@root/src/utils/briefPDF';

const AppContext = createContext();

const AppContextProvider = React.memo((props) => {
  let authenticatedDefault = false;

  if (JSON.parse(localStorage.getItem('authenticated'))) {
    authenticatedDefault = JSON.parse(localStorage.getItem('authenticated')).status;
  }

  const totalSteps = formData.length;
  const [currentStep, setCurrentStep] = useState(authenticatedDefault ? 1 : 0);
  const [authenticated, setAuthenticated] = useState(authenticatedDefault);
  const [sandboxMode, setSandboxMode] = useState(false);
  const [fields, setFields] = useState(JSON.parse(localStorage.getItem('fields')) || {});
  const [formMessage, setFormMessage] = useState({});

  const appState = {
    currentStep,
    setCurrentStep,
    totalSteps,
    fields,
    setFields,
    authenticated,
    setAuthenticated,
    sandboxMode,
    setSandboxMode,
    formMessage,
    setFormMessage,
    briefPDF: briefPDF(fields),
  };

  return <AppContext.Provider value={{ appState }}>{props.children}</AppContext.Provider>;
});

export { AppContext, AppContextProvider };
