import styled from '@emotion/styled/macro';

export const Container = styled('div')`
  max-width: ${({ theme }) => theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space[3]};
`;

export default {
  Container,
};
