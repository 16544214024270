import React, { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';
import Button from 'Elements/Button';

export const FormEnding = styled.div`

`;

export const SendOutput = styled.div`
  margin-bottom: ${({ theme }) => theme.space[4]};
  font-weight: bold;
  text-align: center;
`;

export const CenteredButtonHolder = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin-left: ${({ theme }) => theme.space[2]};
    margin-right: ${({ theme }) => theme.space[2]};
  }
`;

const sendMail = async (recipients = [], senders = [], title = 'Beyond Brief', pdf) => {
  const formData = new FormData();

  formData.append('recipients', recipients);
  formData.append('senders', senders);
  formData.append('title', title);

  if (pdf) {
    formData.append('pdf', pdf, `${title}.pdf`);
  }

  const mailResponse = await fetch('/mail', {
    method: 'POST',
    body: formData,
  });

  const response = mailResponse.ok ? await mailResponse.json() : Promise.reject(mailResponse);

  return response;
};

export default () => {
  const { appState } = useContext(AppContext);
  const {
    briefPDF,
    fields,
    authenticated,
    setAuthenticated,
    setSandboxMode,
    setCurrentStep,
    setFormMessage,
  } = appState;
  const [recipients, setRecipients] = useState([]);
  const [senders, setSenders] = useState([]);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  useEffect(() => {
    const selectedRecipients = [];
    const selectedSenders = [];

    Object.keys(fields).forEach((fieldIndex) => {
      const field = fields[fieldIndex];

      if (field.id === 'senders') {
        Object.keys(field.value).forEach((key) => {
          const value = field.value[key];
          selectedSenders.push(value);
        });
      }

      if (field.id === 'recipients') {
        Object.keys(field.value).forEach((key) => {
          const value = field.value[key];
          selectedRecipients.push(value);
        });
      }
    });

    setRecipients(selectedRecipients);
    setSenders(selectedSenders);
  }, [fields]);

  const sendClick = (e) => {
    e.target.blur();

    let title = 'Beyond Brief';
    let client;

    setSendButtonDisabled(true);

    Object.keys(fields).forEach((fieldIndex) => {
      const field = fields[fieldIndex];

      if (field.id === 'title') {
        title = field.value;
      }

      if (field.id === 'client') {
        client = field.value;
      }
    });

    title = client ? `${client} - ${title}` : title;

    sendMail(recipients, senders, title, briefPDF.getBlob())
      .then(res => {

        if (res.mail) {
          if (res.mail.error) {
            setFormMessage({
              type: 'error',
              errorMessage: res.mail.error.response,
            });
          } else {
            setSendButtonDisabled(false);
  
            setFormMessage({
              type: 'sent-success',
            });
          }
        } else {
          setFormMessage({
            type: 'error',
            errorMessage: res.message,
          });
        }
      })
      .catch(err => {
        err.json().then((data) => {
          if (data.type === 'incorrect-token') {
            setSandboxMode(false);
            setAuthenticated(false);
            setCurrentStep(0);

          } else {
            setFormMessage({
              type: 'error',
              errorMessage: data.message,
            });
          }
        }).catch(() => {
          setFormMessage({
            type: 'error',
            errorMessage: `${err.status} - ${err.statusText}`,
          });
        });
      });
  };

  const turnOffSandbox = () => {
    setSandboxMode(false);
    setCurrentStep(0);
  };

  return (
    <>
      <FormEnding>
        {authenticated ? (
          <CenteredButtonHolder>
            <Button elem="button" text="Skicka" disabled={sendButtonDisabled} onClick={sendClick} />
          </CenteredButtonHolder>
        ) : (
          <Button elem="button" text="Logga in" disabled={sendButtonDisabled} onClick={turnOffSandbox} />
        )}
      </FormEnding>
    </>
  );
};
