module.exports = {
  emailRecipients: {
    'Gabriela Badea': 'gabriela@onestepbeyond.se',
    'Klaus Buchholz': 'klaus@onestepbeyond.se',
    'Gabriel Engberg': 'gabriel@onestepbeyond.se',
    'Anders Englund': 'anders.e@onestepbeyond.se',
    'Anna Englund Heilmann': 'anna@onestepbeyond.se',
    'Adam Eriksson': 'adam@onestepbeyond.se',
    'Tobias Erlén': 'tobias@onestepbeyond.se',
    'Sandra Serafia Johansson': 'sandra@onestepbeyond.se',
    'Dan Karlsson': 'dan@onestepbeyond.se',
    'Josefine Kratz': 'josefine@onestepbeyond.se',
    'Niclas Lidberg von Schantz': 'niclas@onestepbeyond.se',
    'Maria Lundkvist': 'maria@onestepbeyond.se',
    'Linnéa Markert': 'linnea@onestepbeyond.se',
    'Lisa Nelson': 'lisa@onestepbeyond.se',
    'Christine Nordgreen Lind': 'christine@onestepbeyond.se',
    'Anders Nygren': 'anders.n@onestepbeyond.se',
    'Isak Sandin': 'isak@onestepbeyond.se',
    'Simon Schultz': 'simon@onestepbeyond.se',
    'Stefan Ström': 'stefan@onestepbeyond.se',
    'Gustav S Lindwall': 'gustav@onestepbeyond.se',
    'Beyond Webb': 'webb@onestepbeyond.se',
    'Ledningen': 'ledning@onestepbeyond.se',
  },
  testEmailAddress: 'isak@onestepbeyond.se',
};
