import React from 'react';
import Media, { MediaProvider } from 'react-media-universal';
import { withTheme } from 'emotion-theming';

export const MediumDown = withTheme(({ children, theme }) => (
    <MediaProvider>
      <Media query={`(max-width: ${theme.deviceSizes.medium})`} defaultMatches={false}>
        {children}
      </Media>
    </MediaProvider>
));

export const MediumUp = withTheme(({ children, theme }) => (
  <MediaProvider>
    <Media query={`(min-width: ${theme.deviceSizes.mediumUp})`} defaultMatches={false}>
      {children}
    </Media>
  </MediaProvider>
));
