import jsPDF from 'jspdf';
import plain from '@root/src/pdf-font';

export default (fields) => {
  let doc = new jsPDF();
  const docTitle = 'beyond-brief.pdf';
  const pageHeight = 297;
  const pageWidth = 210;
  const docMargin = 25;
  const docTopMargin = 30;
  const textMaxWidth = pageWidth - docMargin * 2;
  let promptY = 0;

  const addFooter = (i, numberOfPages) => {
    doc
      .setFont(plain)
      .setFontType('bold')
      .setFontSize(10)
      .setTextColor(25, 25, 25);
    doc.text(`Sida ${i} av ${numberOfPages}`, pageWidth / 2, pageHeight - 10, {
      align: 'center',
    });
  };

  const writeField = (title, value, x = 0, y = 0) => {
    const fontSize = 12;
    const lineHeight = 6;
    const titleLines = doc.splitTextToSize(title, textMaxWidth);
    const valueLines = doc.splitTextToSize(value, textMaxWidth);
    let titleHeight = titleLines.length * lineHeight;
    let valueHeight = valueLines.length * lineHeight;
    const spacing = 1;

    doc.setLineHeightFactor(1.4);

    if (y + titleHeight + valueHeight + spacing > pageHeight - docTopMargin / 1.5) {
      doc.addPage();
      promptY = docTopMargin;
      y = docTopMargin;
    }

    doc
      .setFont(plain)
      .setFontType('bold')
      .setTextColor(25, 25, 25)
      .setFontSize(fontSize)
      .text(title, x, y, {
        maxWidth: textMaxWidth,
      });

    let valuesY = y + titleHeight + spacing;

    valueLines.forEach((line) => {
      let newLineHeight = lineHeight;
      if (line === '') {
        newLineHeight = lineHeight - 2;
      }

      doc
        .setFont(plain)
        .setFontType('normal')
        .text(line, x, valuesY, {
          maxWidth: textMaxWidth,
        });
      valuesY += newLineHeight;
    });

    return titleHeight + spacing + valueHeight;
  };

  const assemblePDF = () => {
    doc = new jsPDF();
    promptY = docTopMargin;

    doc.setFont(plain);
    doc.setFontType('black');
    doc.setFontSize(26);

    doc.text('Beyond Brief', pageWidth / 2, promptY, {
      align: 'center',
      maxWidth: textMaxWidth,
    });

    promptY += 20;

    const fieldSpacing = 10;

    Object.keys(fields).sort().forEach((fieldKey, index) => {
      const field = fields[fieldKey];

      let stringValue = field.value;

      if (typeof field.value === 'object') {
        stringValue = '';
        const keys = Object.keys(field.value).sort();

        keys.forEach((key, index) => {
          stringValue += field.value[key] ? `${key} \n` : '';
        });
      }

      if (stringValue.endsWith(' \n')) {
        stringValue = stringValue.slice(0, stringValue.length - 2);
      }

      if (stringValue !== '') {
        const height = writeField(field.title, stringValue, docMargin, promptY);
        promptY += height + fieldSpacing;
      }
    });

    const numberOfPages = doc.internal.getNumberOfPages();

    for (var i = 0; i < numberOfPages; i += 1) {
      doc.setPage(i + 1);
      addFooter(i + 1, numberOfPages);
    }
  };

  const getBlob = () => {
    assemblePDF();
    return doc.output('blob');
  };

  const getObjectURL = () => {
    return URL.createObjectURL(getBlob());
  };

  const download = (filename) => {
    assemblePDF();
    doc.save(filename || docTitle);
  };

  return {
    assemblePDF,
    getBlob,
    getObjectURL,
    download,
  };
};
