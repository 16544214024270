import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import Icon from 'Elements/Icon';

export const CheckboxItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  input[type="checkbox"] {
    border: 0;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip; rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
  }
`;

export const CheckboxUIBox = styled.div`
  width: 22px;
  height: 22px;
  background: ${({ theme }) => theme.colors.greyLight};
  border-radius: 3px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .1s;
  cursor: pointer;

  .icon {
    font-size: 12px;
    color: rgba(255, 255, 255, 0);
    transition: color .1s;
  }

  ${({ checked, theme }) => checked && `
    background: ${theme.colors.black};

    .icon {
      color: #fff;
    }
  `}
`;

export const Checkbox = ({ type = 'checkbox', name, checked = false, onChange, value }) => (
  <input type={type} name={name} checked={checked} onChange={onChange} value={value} />
);

export const CheckboxGroup = ({ options, id, onChange, value }) => {
  const [checkedItems, setCheckedItems] = useState(value);

  const handleChange = (e) => {
    const item = e.target.value;
    const isChecked = e.target.checked;
    const newCheckedItems = Object.assign({}, checkedItems, { [`${item}`]: isChecked });
    setCheckedItems(newCheckedItems);
    onChange && onChange(newCheckedItems);
  };

  useEffect(() => {
    if (value) {
      setCheckedItems(value);
    }
  }, [value]);

  const CheckboxOptions = Object.keys(options).map((key, index) => {
    const value = options[key];
    return (
      <CheckboxItem key={index}>
        <label className="checkbox-label">
          <CheckboxUIBox checked={checkedItems[value]}>
            <Icon name="check" />
          </CheckboxUIBox>
          <Checkbox checked={checkedItems[value]} name={id} onChange={handleChange} value={value} />
          {key}
        </label>
      </CheckboxItem>
    );
  });

  return <fieldset>{CheckboxOptions}</fieldset>;
};

export default Checkbox;
