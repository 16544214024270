import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';
import StepNavigator from 'Blocks/StepNavigator';
import SlidingForm from 'Parts/SlidingForm';
import SplashMessage from 'Parts/SplashMessage';

export const ContentContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
  height: calc(100% - ${({ theme }) => theme.sizes.headerHeight} - 60px);
  top: calc(${({ theme }) => theme.sizes.headerHeight} + 60px);

  ${({ theme }) => theme.mediaQuery.md} {
    top: calc(${({ theme }) => theme.sizes.headerHeight} + ${({ theme }) => theme.space[3]});
  }
`;

export const ContentContainerInner = styled.div`
  position: absolute;
  width: 100%;
`;

export const NavigationHolder = styled.div`
  position: fixed;
  max-width: 1080px;
  width: 100%;
  top: calc(${({ theme }) => theme.sizes.headerHeight} + 55px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
`;

export default () => {
  const { appState } = useContext(AppContext);
  const {
    formMessage,
  } = appState;

  return (
    <>
      {Object.keys(formMessage).length > 0 ? (
        <SplashMessage type={formMessage.type} errorMessage={formMessage.errorMessage} />
      ) : (
        <ContentContainer>
          <NavigationHolder>
            <StepNavigator direction="previous" />
            <StepNavigator direction="next"/>
          </NavigationHolder>
          <ContentContainerInner>
            <SlidingForm />
          </ContentContainerInner>
        </ContentContainer>
      )}
    </>
  );
};
