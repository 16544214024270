import React, { useContext } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';
import BeyondDesk from 'Assets/svg-images/beyond-desk.svg';
import BeyondMonkey from 'Assets/svg-images/beyond-monkey.svg';
import Button from 'Elements/Button';

export const SplashMessageHolder = styled.div`
  max-width: 1000px;
  position: relative;
  top: calc(${({ theme }) => theme.sizes.headerHeight} + 60px);
  padding: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.ld} {
    max-width: 800px;
  }

  ${({ theme }) => theme.mediaQuery.md} {
    flex-direction: column;
    top: calc(${({ theme }) => theme.sizes.headerHeight} + 16px);
  }

  .message-illustration {
    flex: 0 0 430px;
    margin: 0 auto;
    width: 430px;
    height: 430px;
    border-radius: 50%;
    background: linear-gradient(-30deg, #7BD6FF 10%, #EBF9FF);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.mediaQuery.ld} {
      flex: 0 0 250px;
      width: 250px;
      height: 250px;
    }

    ${({ theme }) => theme.mediaQuery.md} {
      flex: 0 0 200px;
      width: 200px;
      height: 200px;
      margin-bottom: ${({ theme }) => theme.space[3]};
    }

    img {
      width: 65%;
      position: relative;
      top: -4%;
      left: 0%;
    }
  }

  .message-content {
    margin-left: ${({ theme }) => theme.space[6]};

    ${({ theme }) => theme.mediaQuery.md} {
      margin: 0;
      text-align: center;
    }

    h2 {
      margin-bottom: ${({ theme }) => theme.space[3]};
    }

    .button-p {
      margin-top: ${({ theme }) => theme.space[3]};
    }
  }

  .error-message {
    background: ${({ theme }) => theme.colors.greyLight};
    padding: 8px 10px;
    border-radius: 3px;
    font-family: 'Monaco', 'Courier New', mono-space;
    font-size: 14px;
    display: inline-block;
  }

  ${({ type, theme }) => type === 'error' && `
    .message-illustration {
      background: linear-gradient(-30deg, #FDC1C1 10%, #F8F8F8);

      img {
        width: 63%;
        position: relative;
        top: -10%;
        left: 0%;
      }
    }

    .button-p {
      margin-top: ${theme.space[4]} !important;
    }
  `}
`;

export default ({
  type = 'error',
  errorMessage = '',
}) => {
  const { appState } = useContext(AppContext);
  const {
    setFields,
    setCurrentStep,
    setFormMessage,
  } = appState;

  const newBrief = () => {
    localStorage.removeItem('fields');
    setFields({});
    setFormMessage({});
    setCurrentStep(1);
  };

  const getMessage = () => {
    let message = {};

    if (type === 'sent-success') {
      message = {
        image: BeyondDesk,
        heading: 'Snyggt jobbat!',
        text: `
          <p>
            <strong>Briefen har skickats.</strong><br />
            Gå nu och ta en välförtjänt vila i Mistakes. Dra för gardinerna och slut ögonen. Jag kommer inte skvallra.
          </p>
        `,
        button: {
          text: 'Skapa ny',
          callback: newBrief,
        },
      };

    } else if (type === 'error') {
      message = {
        image: BeyondMonkey,
        heading: 'Error Error!',
        text: `
          <p>
            <strong>Något gick fel.</strong><br />
            Så kan det gå ibland. Det viktiga är att vi inte skyller på utvecklaren! Ditt arbete bör vara sparat, ta det lugnt. 💩
          </p>
          ${errorMessage && `
            <p>
              <span class="error-message">${errorMessage}</span>
            </p>
          `}
        `,
        button: {
          text: 'Ladda om appen',
          callback() {
            window.location.reload();
          },
        },
      };
    }

    return message;
  };

  const message = getMessage();

  return (
    <SplashMessageHolder type={type}>
      <div className="message-illustration">
        <img src={message.image} alt=""/>
      </div>
      <div className="message-content">
        <h2>{message.heading}</h2>
        <p dangerouslySetInnerHTML={{ __html: message.text }} />
        {message.button && (
          <p className="button-p">
          <Button elem="button" text={message.button.text} onClick={message.button.callback} />
          </p>
        )}
      </div>
    </SplashMessageHolder>
  );
};
