import React, { useContext } from 'react';
import { AppContext } from 'Components/AppContext';
import Header from 'Parts/Header';
import Content from 'Parts/Content';
import LoginPopup from 'Parts/LoginPopup';
import posed, { PoseGroup } from 'react-pose';

const ContentPosed = posed('div')({
  enter: {
    opacity: 1,
    scale: 1,
    transition: {
      opacity: {
        duration: 200,
      },
      scale: {
        duration: 300,
      },
    },
  },
  exit: {
    opacity: 0,
    scale: .5,
    transition: {
      opacity: {
        duration: 200,
      },
      scale: {
        duration: 300,
      },
    },
  },
});

function App() {
  const { appState } = useContext(AppContext);
  const {
    authenticated,
    sandboxMode,
  } = appState;

  return (
    <>
      <Header />
      <PoseGroup>
        {authenticated || sandboxMode ? (
          <ContentPosed key="content">
            <Content />
          </ContentPosed>
        ) : (
          <ContentPosed key="login-popup">
            <LoginPopup />
          </ContentPosed>
        )}
      </PoseGroup>
    </>
  );
}

export default App;
