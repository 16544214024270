import React, { useContext, useState } from 'react';
import styled from '@emotion/styled/macro';
import { AppContext } from 'Components/AppContext';
import Button from 'Elements/Button';

export const LoginPopup = styled.div`
  padding: 140px 0 0;
  margin: 0 auto;
  max-width: 580px;
  border-radius: 3px;
  text-align: center;
  padding: 140px ${({ theme }) => theme.space[3]} 0;

  ${({ theme }) => theme.mediaQuery.md} {
    padding: 100px ${({ theme }) => theme.space[3]} 0;
  }

  span {
    display: block;
    margin-bottom: ${({ theme }) => theme.space[1]};
    text-align: left;
  }

  label {
    display: block;
    font-family: 'PlainBold', sans-serif;
    margin-bottom: 2px;
    text-align: left;
  }

  input[type="password"] {
    appearance: none;
    width: 100%;
    border: none;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Plain', sans-serif;
    background: ${({ theme }) => theme.colors.greyLight};
    border-radius: 3px;
    padding: ${({ theme }) => theme.space[2]};
    margin-bottom: ${({ theme }) => theme.space[6]};
    outline: none;
  }
`;

export const AppTitle = styled.h1`
  font-weight: 900;
  font-size: 42px;
  margin: 0 0 32px 0;
`;

export const FailMessage = styled.p`
  font-weight: bold;
  margin-bottom: 32px !important;
  color: red;
`;

const checkPassword = async (password) => {
  const formData = new FormData();

  formData.append('password', password);

  const response = await fetch('/getToken', {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  });

  const body = await response.json();

  if (response.status !== 200) {
    throw Error(body.message);
  }

  return body;
};

export default () => {
  const { appState } = useContext(AppContext);
  const {
    setCurrentStep,
    setAuthenticated,
  } = appState;
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [failMessage, setFailMessage] = useState('');

  const authenticate = (e) => {
    e.preventDefault();
    setLoginButtonDisabled(true);

    if (passwordValue.length > 0) {
      checkPassword(passwordValue)
        .then(res => {
          setLoginButtonDisabled(false);

          if (res.status === 'correct') {
            setFailMessage('');
            setCurrentStep(1);
            setAuthenticated(true);
            localStorage.setItem('authenticated', JSON.stringify({ status: true }));

          } else {
            setFailMessage('Fel lösenord');
          }
        })
        .catch(err => {
          console.log(err);
        });

    } else {
      setLoginButtonDisabled(false);
      setFailMessage('Skriv in ett lösenord');
    }
  };

  return (
    <LoginPopup>
      <AppTitle>Beyond Brief</AppTitle>
      <form target="" onSubmit={authenticate}>
        <label htmlFor="authentication-input">Lösenord</label>
        <span>Skriv in lösenord för att använda all funktionalitet.</span>
        <input type="password" placeholder="Skriv lösenord..." value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} autoFocus="autofocus" id="authentication-input" />
        {failMessage && <FailMessage>{failMessage}</FailMessage>}
        <Button elem="button" text="Logga in" disabled={loginButtonDisabled} />
      </form>

    </LoginPopup>
  );
};
