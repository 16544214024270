/* eslint no-unused-vars: 0 */
import React from 'react';
import styled from '@emotion/styled/macro';
import iconSprite from 'Assets/icon-sprite.svg';

export const IconSVG = styled.svg`

`;

export default ({ name }) => (
  <IconSVG className={`icon icon-${name}`}>
    <use xlinkHref={`#icon-sprite_icon-${name}`} />
  </IconSVG>
);
