import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

const deviceSizes = {
  small: '480px',
  smallUp: '481px',
  medium: '767px',
  mediumUp: '768px',
  large: '1024px',
  largeUp: '1025px',
};

const spaceInt = [
  4,
  8,
  16,
  24,
  32,
  40,
  48,
  56,
  64,
]

const space = [
  `${spaceInt[0]}px`,
  `${spaceInt[1]}px`,
  `${spaceInt[2]}px`,
  `${spaceInt[3]}px`,
  `${spaceInt[4]}px`,
  `${spaceInt[5]}px`,
  `${spaceInt[6]}px`,
  `${spaceInt[7]}px`,
  `${spaceInt[8]}px`,
];

const colors = {
  black: '#000',
  white: '#fff',
  greyLight: '#EFEFEF',
  brand: '#36A9E1',
  bodyBgColor: '#fff',
};

const sizes = {
  maxWidth: '1380px',
  edgeMargin: space[4],
  headerHeight: '60px',
};

const misc = {
  transition: '.2s',
  slideTransition: '.25s',
  pLineHeight: '1.6rem',
  pMargin: '1.25rem',
};

const mediaQuery = {
  sd: `@media only screen and (max-width: ${deviceSizes.small})`,
  su: `@media only screen and (min-width: ${deviceSizes.smallUp})`,
  md: `@media only screen and (max-width: ${deviceSizes.medium})`,
  mu: `@media only screen and (min-width: ${deviceSizes.mediumUp})`,
  ld: `@media only screen and (max-width: ${deviceSizes.large})`,
  lu: `@media only screen and (min-width: ${deviceSizes.largeUp})`,
};

const elements = {
  container: `
    width: 100%;
    max-width: ${sizes.maxWidthM};
    margin: 0 auto;
    padding: 0 ${sizes.edgeMargin};

    ${mediaQuery.md} {
      padding: 0 calc(${sizes.edgeMargin} / 2);
    }
  `,
};

const mixins = {
  center: `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);`,

  softLinearGradient(color = '0, 0, 0', startpoint = 1, deg = '180deg') {
    return `
      background-image: linear-gradient(${deg},
        rgba(${color}, ${startpoint}) 0%,
        rgba(${color}, ${startpoint * 0.738}) 19%,
        rgba(${color}, ${startpoint * 0.541}) 34%,
        rgba(${color}, ${startpoint * 0.382}) 47%,
        rgba(${color}, ${startpoint * 0.278}) 56.5%,
        rgba(${color}, ${startpoint * 0.194}) 65%,
        rgba(${color}, ${startpoint * 0.126}) 73%,
        rgba(${color}, ${startpoint * 0.075}) 80.2%,
        rgba(${color}, ${startpoint * 0.042}) 86.1%,
        rgba(${color}, ${startpoint * 0.021}) 91%,
        rgba(${color}, ${startpoint * 0.008}) 95.2%,
        rgba(${color}, ${startpoint * 0.002}) 98.2%,
        rgba(${color}, 0) 100%
      );
    `;
  },
}

export const theme = {
  colors,
  deviceSizes,
  elements,
  mediaQuery,
  mixins,
  sizes,
  spaceInt,
  space,
  misc,
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

const GlobalStyles = () => (
  React.createElement(
    Global, {styles: `
      html {
        box-sizing: border-box;
      }

      html,
      body {
      	margin: 0;
      	padding: 0;
      	height: 100%;
        background: ${colors.bodyBgColor};
      }

      html {
        font-size: 18px;
        font-size-adjust: none;
        font-family: 'PlainUltralight', sans-serif;
        font-weight: 200;
      	-moz-osx-font-smoothing: grayscale;
      	-webkit-text-size-adjust: none;
      	-webkit-font-smoothing: antialiased;

        ${mediaQuery.md} {
          font-size: 16px;
        }
      }

      body {

        & > #__next {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100%;
        }
      }

      #root, .App {
        height: 100%;
      }

      main {
        flex: 1 1 auto;
      }

      *, *:before, *:after {
        box-sizing: inherit;
      }

      img {
        display: block;
        max-width: 100%;
      }

      a,
      button {
        outline: none;
        text-decoration: none;
      }

      a {
        color: ${colors.primary};
      }

      p {
        margin: 0 0 ${misc.pMargin} 0;
        line-height: ${misc.pLineHeight};

        &:last-of-type {
          margin: 0;
        }
      }

      strong {
        font-weight: 400;
        font-family: 'PlainBold', sans-serif;
      }

      h1,
      h2,
      h3 {
        font-weight: 700;
        font-family: 'PlainBlack', sans-serif;
        margin: 0 0 calc(${misc.pLineHeight} / 2) 0;
      }

      h1 {
        font-size: 3.1rem;

        &.center {
          text-align: center;
        }

        ${mediaQuery.md} {
          font-size: 2rem;
        }
      }

      h2 {
        font-size: 2.6rem;
        font-weight: 900;
        position: relative;
        left: 1px;
      }

      h3 {
        font-size: 1.85rem;
      }

      .icon-sheet {
        width: 1em;
        height: 1em;
        vertical-align: middle;
        overflow: hidden;
      }

      .icon {
        fill: currentColor;
        width: 1em;
        height: 1em;
        vertical-align: middle;
        overflow: hidden;
      }

      @keyframes fadein {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `,
    },
    null,
  )
);

export { Theme, GlobalStyles };
